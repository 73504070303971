<template>
  <div class="vip-box">
    <ul class="vip-task-box">
      <li class="task-li" :class="classType">
        <div class="vip-left">
          <div class="icon-img">
            <img :src="boxList.iconImg">
          </div>
        </div>
        <div class="vip-center">
          <h3 class="vip-title">{{ boxList.taskName }}</h3>
          <p class="vip-text" v-html="boxList.desc"></p>
          <div class="vip-progress" v-if="boxList.completeText">
            <div class="img-icon">
              <img src="data:image/png;base64,iVBORw0KGgo
            AAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAk1BMVEUAAAD/
            yDL/yTL/yTL/yTP/vzL/yDH/yTL/yTH/yDL/yDH/yDH/yTL/
            yTH/yjD/ziz/yTL/yTL/yTL/yTL/yDH/yDP/yjH/yjL/
            zi7/zTL/yDL/yTH/yDL/yTH/yDH/yTP/yDH/yTL/yTL/
            yjL/wy3/yTL/yTL/yDL/yjH/yTL/zDP/yDD/xzD/zTL/yDL/
            yzD/yDK9Nm+MAAAAMHRSTlMA9/OBGQXk4dzHw71tXjoL0ri
            nh1lGPiYbD/vo162SjX1UQjUR8LOZdkw8MCAUnkU+FHxN
            AAABKUlEQVQoz12S2ZaCMBBES9kVQVQUXECWcZ+Z/P/XS
            Ug3IdynVC5JcwrQcRYKCxKL0hlEQhszuwv2jFLCOhJE0YWC
            QwRmycczZHx4iYGXILbY8vJFjo+7ZZoDyNPS5cPMXrgrB0
            5Uhbt9DGflij3GWC2azVy9wC1Fa8Gk3QhNkk3swRdjTpFh
            bVeY/BzGOqDdeXiilR9ruxLEdbwc8PjKHI7PF+XTzmcPeR
            O3uiOrirykH4pZ/Sc3NqwvMj2g2cmNgNNCpmOlK+7r87Tu
            PQ+rVbk+a+rTM54WC9ZXlW8U7yreWb/5XwBkl6GKTzDrPo
            doEhHUqNRX0a0+1Wz1ywVnelhzEVMCB5p4PbGejTG/C8Ou
            PzBxrPkgj0WsBWNbaoJfHkyhR7z/G3PoFyLETGv1hJHVA
            AAAAElFTkSuQmCC">
            </div>
            <p v-html="boxList.completeText"></p>
          </div>
        </div>
        <div class="vip-right"
          v-if="boxList.btnText">
          <button class="y-button y-button--danger"
            :class="{ isComplete: boxList.isComplete }"
            @click="clickAction(boxList)">{{ boxList.btnText }}</button>
        </div>
        <slot name="bottom"></slot>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'CardBox',
  props: {
    boxList: {
      type: Object,
      default: () => ({
        iconImg: '',
      }),
    },
    classType: {
      type: String,
      default: '',
    },
  },
  methods: {
    clickAction(data) {
      !data.isComplete && this.$emit('click-action', data);
    },
  },
};
</script>
<style scoped lang="scss">
  .vip-task-box {
    .task-li {
      display: flex;
      &.vipBox {
        flex-wrap: wrap;
      }
      padding: 23px 0 28px 0;
      background: #FFFFFF;
      border-radius: 12px;
      margin-top: 20px;
      >div {
        margin-right: 40px;
        display: flex;
        align-items: center;
        &.vip-left {
          margin-left: 34px;
        }
        &.vip-right {
          margin-right: 26px !important;
        }
        &.vip-center {
          display: block;
          width: 350px;
        }
        .icon-img {
          width: 90px;
          height: 90px;
          background: #FCF2E6;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #FCF2E6;
          border-radius: 50%;
          img {
            width: 68px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .vip-center {
        .vip-title {
          font-size: 28px;
          font-weight: 600;
          color: #333333;
          line-height: 40px;
          margin-bottom: 4px;
        }
        .vip-text {
          font-size: 22px;
          font-weight: 400;
          color: #999999;
          line-height: 30px;
        }
      }
    }
  }
  .y-button--danger {
    width: 126px;
    height: 54px;
    text-align: center;
    // line-height: 54px;
    color: #fff;
    background: linear-gradient(90deg, #EA524F 0%, #FF9669 100%);
    border-radius: 42px;
    font-size: 24px;
    font-weight: 500;
    &.isComplete {
      border-radius: 42px;
      border: 2px solid #EAE7E7;
      background: #fff;
      color: #D8D8D8;
      padding: 0;
    }
  }
  .vip-progress {
    display: flex;
    margin-top: 14px;
    font-size: 22px;
    font-weight: 400;
    color: #F7B500;
    line-height: 15px;
    width: 100%;
    align-items: center;
    .img-icon {
      width: 30px;
      flex-shrink: 0;
      margin-right: 10px;
    }
    img {
      width: 100%;
      margin-right: 2px;
    }
    /deep/ .red {
      color: #EC514F;
    }
  }
</style>
